<template>
    <!--    <div>-->
    <div class="row mt-5 action">
        <div class="col-12 col-sm-12 offset-sm-12 ">
            <b-card no-body class="overflow-hidden  text-center" style="max-width: 700px;" title="Crawler Login">
                <b-row no-gutters class="m-5">
                    <b-col md="4">
                        <b-card-img src="Vertical_SAS_Color.png" alt="AdRetreaver" class="rounded-0" loading="lazy"></b-card-img>
                    </b-col>
                    <b-col md="8">
                        <b-card-body class="p-5" title="Crawler Login">
                            <b-card-text>
                                Login with your Adretreaver AD account
                            </b-card-text>
                            <form :action="$root.serverUrl + '/api/auth'" class="">
                                <div v-if="errormsg" class="alert alert-danger">{{errormsg||'Invalid login'}}</div>
                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary btn-block" value="Login"/>
                                </div>
                            </form>
                        </b-card-body>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
    <!--    </div>-->
</template>

<script>
export default {
    name: "login",
    props: ["errormsg"],
};
</script>

<style scoped>
.center {
    margin: auto;
    /*width: 60%;*/
    border-left: 8px solid #b3b0b0;
    border-bottom: 8px solid #b3b0b0;
    /*padding: 10px;*/
}
.action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;

}
</style>
