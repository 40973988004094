<template>
    <div class="background-div">
<!--        <div class="row mt-5">-->
<!--            <div class="col-12 col-sm-4 offset-sm-4">-->
<!--                <b-card>-->
<!--                    <b-card-body>-->

<!--&lt;!&ndash;                        <b-img src="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"&ndash;&gt;-->
<!--&lt;!&ndash;                               class="w-50  pb-4"></b-img>&ndash;&gt;-->
<!--                        <h4 class="text-center">Crawler Login</h4>-->
<!--                        <p class="mt-3 mb-3">Login with your Adretreaver AD account</p>-->
<!--                        <form :action="$root.serverUrl + '/api/auth'" class="">-->
<!--                            <div v-if="errormsg" class="alert alert-danger">{{errormsg||'Invalid login'}}</div>-->
<!--&lt;!&ndash;                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label>Email Address</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input type="text" class="form-control required" required v-model="form.username"/>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                <label>Password</label>&ndash;&gt;-->
<!--&lt;!&ndash;                                <input type="password" class="form-control required" required v-model="form.password"/>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                            <div class="form-group">-->
<!--                                <input type="submit" class="btn btn-success btn-block" value="Login"/>-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </b-card-body>-->
<!--                </b-card>-->
<!--            </div>-->
<!--        </div>-->
      <login :errormsg = 'errormsg'  />


    </div>
</template>

<script>
    import axios from 'axios';
    import login from "@/components/login/login";
    export default {
        name: 'Login',
        components: {login},
        data: function () {
            return {
                errormsg:'',
                form: {username:'', password: ''}
            }
        },
        created: function(){
            if(this.$route.query.error) {
                this.errormsg =  this.$route.query.error
                window.history.replaceState('', '', '/login')
            }
            this.$root.preloader = false;
            this.$cookies.remove('accessToken');
            this.$cookies.remove('refreshToken');
            this.$cookies.remove('authState');
        },
        methods: {
            process: function(){
                axios.post(`${this.$root.serverUrl}/auth/login`, this.form).then(function(response){
                    console.log(response);
                    if(response.data && response.data.accessToken) {
                        this.$root.user = response.data;
                        this.$root.apikey = response.data.accessToken;
                        localStorage.setItem('apikey', response.data.accessToken);
                        localStorage.setItem('refreshToken', response.data.refreshToken);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                        window.location.href = '/';
                    } else {
                        this.errormsg = response.data.error||'Invalid Login';
                    }
                }.bind(this)).catch(function (err) {
                    this.errormsg = err.message;
                }.bind(this))
            }
        }
    }
</script>

<style scoped>
html, body{
    height: 100%;
    margin: 0;
    padding: 0;
}

.background-div {
    /* Set the size and other styles for your div */
    /*width: 500px;*/
    /*height: 300px;*/
    /* Specify the path to the image in the public folder */
    background-image: url('/Banner_InternalSASDomains_Banner_110223_D2.jpg');
    /* Adjust background properties as needed */
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-right: unset !important;
    padding-left: unset !important;
    /* Add any other styles you need for your div */
    /*color: white;*/
}
</style>
